export const routes = [
    {
        path: "",
        component: () => import("@/views/AppComponent"),
        children: [
            {
                path: "",
                name: "home",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/home/HomeComponent"),
            },
            {
                path: "aktualnosci",
                name: "news",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/news/NewsComponent"),
            },
            {
                path: "aktualnosci/:id",
                name: "news-single",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/news/NewsSingleComponent"),
            },
            {
                path: "moje-konto",
                component: () =>
                    import("@/components/modules/account/AccountComponent"),
                children: [
                    {
                        path: "",
                        name: "account",
                        meta: { requiresAuth: true },
                        component: () =>
                            import(
                                "@/components/modules/account/AccountIndexComponent"
                            ),
                    },
                    {
                        path: "edycja-danych",
                        name: "editAccount",
                        meta: { requiresAuth: true },
                        component: () =>
                            import(
                                "@/components/modules/account/EditAccountComponent"
                            ),
                    },
                ],
            },
            {
                path: "moje-premie",
                name: "bonuses",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/bonuses/BonusesComponent"),
            },
            {
                path: "faktura/:id",
                name: "invoice",
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        "@/components/modules/InvoiceFileComponent"
                    ),
            },
            {
                path: "zasady",
                name: "rules",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/rules/RulesComponent"),
            },
            {
                path: "historia-faktur/:id",
                name: "invoicesHistory",
                props: true,
                meta: { requiresAuth: true},
                component: () =>
                    import("@/components/modules/home/ph_view/InvoicesHistory"),
            },
            {
                path: "benefits",
                name: "benefits",
                meta: { requiresAuth: true },
                component: () =>
                    import("@/components/modules/benefits/BenefitsComponent"),
            },
            {
                path: "check-agreements",
                name: "checkAgreements",
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        "@/components/modules/auth/CheckAgreementsComponent"
                    ),
            },
            {
                path: "check-agreements-for-edition",
                name: "checkAgreementsForEdition",
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        "@/components/modules/auth/CheckAgreementsForEditionComponent"
                    ),
            },
            {
                path: "find-guardian",
                name: "guardian-logged",
                meta: { requiresAuth: true },
                component: () => import("@/components/modules/MapComponent"),
            },
        ],
    },
    {
        path: "",
        component: () => import("@/views/AuthComponent"),
        children: [
            {
                path: "",
                component: () =>
                    import("@/components/modules/auth/LoginComponent"),
                children: [
                    {
                        path: "login",
                        name: "login",
                        component: () =>
                            import(
                                "@/components/modules/auth/LoginFormComponent"
                            ),
                    },
                    {
                        path: "verify/:id/:hash",
                        name: "verify",
                        component: () =>
                            import(
                                "@/components/modules/auth/LoginFormComponent"
                            ),
                    },

                    {
                        path: "reset-password/:token?",
                        name: "resetPassword",
                        component: () =>
                            import(
                                "@/components/modules/auth/ResetPasswordComponent"
                            ),
                    },
                ],
            },
            {
                path: "forgot-password",
                name: "forgotPassword",
                component: () =>
                import("@/components/modules/auth/ForgotPasswordComponent"),
            },
            {
                path: "register",
                name: "register",
                component: () =>
                    import("@/components/modules/auth/RegisterComponent"),
            },
            {
                path: "/l098074561/:hash?",
                name: "admin-login",
                component: () =>
                    import("@/components/modules/auth/AutoLogin.vue"),
            },
        ],
    },
    /** Fix 404 error after refresh page for quardians */
    // {
    //     path: "",
    //     component: () => import("@/components/modules/guardian/GuardianComponent"),
    //     children: [
    //         {
    //             path: "find-a-guardian",
    //             name: "guardian",
    //             meta: { requiresAuth: false },
    //             //component: () => import('@/components/modules/guardian/GuardianComponent')
    //         },
    //     ],
    // },
    // {
    //     path: 'find-a-guardian',
    //     name: 'guardian',
    //     component: () => import('@/components/modules/guardian/GuardianComponent')
    // },
    {
        path: "",
        component: () => import("@/views/AppComponent"),
        children: [
            {
                path: "find-a-guardian",
                name: "guardian",
                meta: { requiresAuth: false },
                component: () => import('@/components/modules/guardian/GuardianComponent')
            },
            {
                path: "*",
                name: "error-404",
                meta: { requiresAuth: false },
                component: () => import("@/components/error/Error404"),
            },
        ]
    }
];
