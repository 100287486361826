const benefits = {
    state: {
        benefits: {
            tyres_amount: 0,
            date_text: '',
            benefits:[],
        },
        benefits_statuses:null
    },

    mutations: {
        benefitsUpdate: function (state, payload) {
            state.benefits = payload;
        },
        benefitsStatusesUpdate: function (state, payload) {
            state.benefits_statuses = payload;
        }
    },

    actions: {
        getBenefits: function (context) {
            window.api.get('benefits')
                .then(response => {
                    context.commit('benefitsUpdate', response.data.data || [])
                })
                .catch(() => {
                    context.commit('benefitsUpdate', [])
                })
        },
        updateBenefitsStatus: function (context) {
            window.api.get('benefits/status')
                .then(response => {
                    context.commit('benefitsStatusesUpdate', response.data.data || null)
                })
                .catch(() => {})
        }
    },

    getters: {
        benefits: function (state) {
            return state.benefits;
        },
        getBenefitsStatus: function (state) {
            return state.benefits_statuses;
        },
    }
}

export default benefits;
