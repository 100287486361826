import Vue from 'vue';
import App from './App.vue';
import api from './helpers/api';
import router from './router';
import store from './store/index';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueCarousel from 'vue-carousel';
import VueGtag from "vue-gtag";

// Vue.config.productionTip = false;
Vue.config.devtools = true

window.api = api;
const moment = require('moment');
require('moment/locale/pl')

Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
});
Vue.use(VueCarousel)
Vue.use(require('vue-moment'), {moment});


let cookiesSettings = localStorage.getItem('cookies');
if (cookiesSettings) {
    cookiesSettings = JSON.parse(cookiesSettings);
    if (cookiesSettings.analytics) {
        Vue.use(VueGtag, {
            config: {
                id: 'G-P31M34RY5H'
            }
        })
    }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('number', function (value, decimals = 0) {
  if (isNaN(value)) {
    value = 0;
  }

  value = parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: decimals});
  return value.replaceAll(',', ' ').replaceAll('.', ',')
})

Vue.filter('money', (value) => {
  return Vue.filter('number')(value) + '\xa0' + 'zł';
})

Vue.filter('text', function (value, textLength) {
    const div = document.createElement('div')
    div.innerHTML = value;
    let text = div.textContent || div.innerText || '';
    return text.substring(0, textLength) + (text.length > textLength ? '...' : '');
});
