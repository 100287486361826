const loader = {
    state: {
        loadingCounter: 0,
    },

    mutations: {
        increaseLoader: function (state) {
            state.loadingCounter += 1;
        },
        decreaseLoader: function (state) {
            state.loadingCounter -= 1;
        },
    },
    getters: {
        isLoading(state) {
            return state.loadingCounter > 0;
        },
    },
};

export default loader;
