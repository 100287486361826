import Vue from 'vue'
import Vuex from 'vuex'

import loader from "./states/loader";
import user from "./states/user";
import news from "./states/news";
import popup from './states/popup';
import benefits from "./states/benefits";

Vue.config.devtools = true
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        openMenu: false,
    },
    mutations: {
        toggleMenu: function (state, open) {
            state.openMenu = open;
        }
    },
    modules: {
        loader: loader,
        benefits: benefits,
        news: news,
        popup: popup,
        user: user,
    }
});

export default store;
