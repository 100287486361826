const news = {
    state: {
        news: null,
        newsHome: null,
    },

    mutations: {
        newsUpdate: function (state, payload) {
            state.news = payload;
        },
        newsHomeUpdate: function (state, payload) {
            state.newsHome = payload;
        }
    },

    actions: {
        getNews: function (context, payload = {category:null, archiwum:0}) {
            let params = '';
            if(payload.category !== null){
                params += '/' + payload.category;
            }
            if(payload.category!== null && payload.archiwum === 1){
                params += '/1';
            }
            window.api.get('news/list' + params)
                .then(response => {
                    context.commit('newsUpdate', response.data.data || [])
                })
                .catch(() => {
                    context.commit('newsUpdate', [])
                })
        },
        getNewsHome: function (context) {
            window.api.get('news/home')
                .then(response => {
                    context.commit('newsHomeUpdate', response.data.data || [])
                })
                .catch(() => {
                    context.commit('newsHomeUpdate', [])
                })
        }
    },

    getters: {
        news: function (state) {
            return state.news;
        },
        newsHome: function (state) {
            return state.newsHome;
        },
        lastEntry: function (state) {
            return state.news ? state.news[0] : {};
        },
        lastThreeEntries: function (state) {
            return state.news ? state.news.slice(0,3) : [];
        }
    }
}

export default news;
