<template>
    <div v-if="show" class="cookie-warn" ref="cookie-warn">
        <div class="cookie-warn__header"><b>CIASTECZKA</b></div>
        <template v-if="!manageCookies">
            <div class="cookie-warn__body">
                <p>Stosujemy pliki cookie w&nbsp;celu zapewnienia prawidłowego funkcjonowania serwisu. Możemy również
                    wykorzystywać pliki cookie własne oraz naszych partnerów w&nbsp;celach analitycznych i&nbsp;marketingowych, w&nbsp;szczególności
                    dopasowania treści reklamowych do Twoich preferencji. Korzystanie z&nbsp;analitycznych i&nbsp;marketingowych
                    plików cookie wymaga Twojej zgody, którą możesz wyrazić, klikając "Zaakceptuj wszystkie". Jeżeli nie chcesz wyrazić
                    zgody na korzystanie przez nas i&nbsp;naszych partnerów z&nbsp;określonych kategorii plików cookie, kliknij
                    "Zarządzaj" i&nbsp;zdecyduj o&nbsp;swoich preferencjach. Wyrażoną zgodę możesz wycofać w&nbsp;każdym momencie
                    poprzez zmianę preferencji plików cookie.</p>
                <p>Korzystanie z&nbsp;plików cookie we wskazanych powyżej celach związane jest z&nbsp;przetwarzaniem Twoich
                    danych osobowych. Administratorem Twoich danych osobowych jest Albedo Marketing sp.&nbsp;z&nbsp;o.o. W&nbsp;pewnych
                    przypadkach administratorami danych mogą być również nasi partnerzy.</p>
                <p>Więcej informacji na temat przetwarzania Twoich danych osobowych z&nbsp;wykorzystaniem plików cookie
                    znajdziesz w&nbsp;<a href="assets/app/pdf/regulamin.pdf?v=2024-06-23#page=18" target="_blank">Polityce prywatności</a>. </p>
            </div>
            <div class="cookie-warn__footer">
                <button class="button button-sec" @click="manageCookies = true">Zarządzaj</button>
                <button class="button button-main" @click="acceptAll">ZAAKCEPTUJ WSZYSTKIE</button>
            </div>
        </template>
        <template v-else>
            <div class="cookie-warn__body">
                <div class="mb-3">Poniżej możesz zarządzać preferencjami dotyczącymi korzystania przez nas i&nbsp;naszych
                    partnerów z&nbsp;plików cookie. Korzystanie z&nbsp;plików cookie analitycznych oraz marketingowych wymaga wyrażenia
                    przez Ciebie zgody. W&nbsp;ramach cookies marketingowych wykorzystujemy pliki cookie związane z&nbsp;wyświetlaniem
                    reklam w&nbsp;związku z&nbsp;korzystaniem ze stron internetowych (reklamowe pliki cookie) oraz pliki cookie pozwalające
                    na docieranie do Ciebie ze spersonalizowaną reklamą w&nbsp;portalach społecznościowych (pliki cookie mediów
                    społecznościowych). Więcej informacji o&nbsp;poszczególnych kategoriach plików cookie, które stosujemy w&nbsp;serwisie,
                    znajdziesz poniżej. Jeżeli chcesz zaakceptować wszystkie pliki cookie, z&nbsp;których korzystamy w&nbsp;serwisie,
                    kliknij "Zaakceptuj wszystkie". Jeżeli natomiast chcesz odrzucić wszystkie pliki cookie niebędące plikami
                    niezbędnymi i&nbsp;funkcjonalnymi wystarczy, że klikniesz "Zapisz i&nbsp;zamknij". Aby zmieniać preferencje plików
                    cookie, zaznacz lub odznacz wybraną kategorię. Masz prawo do wglądu w&nbsp;swoje ustawienia oraz do ich zmiany w&nbsp;dowolnym czasie.
                </div>
                <div v-if="showInformationAboutCookies.required" class="choose-item">
                    <div class="choose-item__header">
                        <div>Niezbędne i&nbsp;funkcjonalne pliki cookie - <a href="#" class="more" @click.prevent="expandItem = expandItem === 1 ? 0 : 1">{{ expandItem === 1 ? 'UKRYJ' : 'POKAŻ'}}</a></div>
                        <div class="text-end">Zawsze aktywne</div>
                    </div>
                    <div class="choose-item__body" :class="{'active': expandItem === 1}">
                        <div class="p-2">
                            Korzystanie przez nas z&nbsp;niezbędnych plików cookie jest konieczne dla prawidłowego funkcjonowania
                            strony internetowej. Pliki te są instalowane w&nbsp;szczególności na potrzeby zapamiętywania sesji
                            logowania lub wypełniania formularzy, a&nbsp;także w&nbsp;celach związanych z&nbsp;ustawieniem opcji
                            prywatności. Funkcjonalne pliki cookie zapamiętują i&nbsp;dostosowują stronę do Twoich wyborów, np.&nbsp;preferencji
                            językowych. Możesz ustawić przeglądarkę tak, aby blokowała lub ostrzegała o&nbsp;niezbędnych i&nbsp;funkcjonalnych
                            plikach cookie, jednak spowoduje to, że niektóre części witryny mogą nie działać prawidłowo.
                        </div>
                    </div>
                </div>
                <div v-if="showInformationAboutCookies.analytics" class="choose-item">
                    <div class="choose-item__header">
                        <div>Analityczne pliki cookie - <a href="#" class="more" @click.prevent="expandItem = expandItem === 2 ? 0 : 2">{{ expandItem === 2 ? 'UKRYJ' : 'POKAŻ'}}</a></div>
                        <input type="checkbox" v-model="settings.analytics">
                    </div>
                    <div :class="{'active': expandItem === 2}" class="choose-item__body">
                        <div class="p-2">
                            Analityczne pliki cookie umożliwiają sprawdzenie liczby wizyt i&nbsp;źródeł ruchu w&nbsp;naszym serwisie.
                            Pomagają nam ustalić, które strony są bardziej, a&nbsp;które mniej popularne, i&nbsp;zrozumieć, jak
                            użytkownicy poruszają się po stronie. Dzięki temu możemy badać statystki i&nbsp;poprawiać wydajność naszej
                            witryny. Informacje zbierane przez te pliki cookie są agregowane, nie mają więc na celu ustalenie Twojej
                            tożsamości. Jeśli nie zezwolisz na stosowanie tych plików cookie, nie będziemy wiedzieć, kiedy odwiedziłeś
                            naszą stronę internetową.
                        </div>
                    </div>
                </div>
                <div v-if="showInformationAboutCookies.adds" class="choose-item">
                    <div class="choose-item__header">
                        <div>Reklamowe pliki cookie - <a href="#" class="more" @click.prevent="expandItem = expandItem === 3 ? 0 : 3">{{ expandItem === 3 ? 'UKRYJ' : 'POKAŻ'}}</a></div>
                        <input type="checkbox" v-model="settings.adds" :disabled="!settings.analytics">
                    </div>
                    <div :class="{'active': expandItem === 3}" class="choose-item__body">
                        <div class="p-2">
                            Reklamowe pliki cookie pozwalają na dopasowanie wyświetlanych treści reklamowych do Twoich
                            zainteresowań, nie tylko na naszej witrynie, ale też poza nią. Mogą być instalowane przez partnerów
                            reklamowych za pośrednictwem naszej strony internetowej. Na podstawie informacji z&nbsp;tych plików
                            cookie i&nbsp;aktywności w&nbsp;innych serwisach jest budowany Twój profil zainteresowań. Reklamowe
                            cookies nie przechowują bezpośrednio Twoich danych osobowych, ale identyfikują Twoją przeglądarkę
                            internetową i&nbsp;sprzęt. Jeśli nie zezwolisz na stosowanie tych plików cookie, dalej będziemy mogli
                            wyświetlać Ci reklamy, jednak nie będą one dopasowane do Twoich preferencji.
                        </div>
                    </div>
                </div>
            </div>
            <div class="cookie-warn__footer">
                <button class="button button-sec" @click="saveSettings">Zapisz i zamknij</button>
                <button class="button button-main" @click="acceptAll">ZAAKCEPTUJ WSZYSTKIE</button>
            </div>
        </template>
    </div>
</template>

<script>
// GTM - https://github.com/gtm-support/vue-gtm
// GA - https://matteo-gabriele.gitbook.io/vue-gtag/
// npm install vue-gtm @gtm-support/vue-gtm

//import {bootstrap} from "vue-gtag";

export default {
    name: "CookieComponent",
    data() {
        return {
            show: false,
            name: 'cookies',
            manageCookies: false,
            showInformationAboutCookies: {
                required: true,
                analytics: true,
                adds: false
            },
            settings: {
                required: true,
                analytics: false,
                adds: false
            },
            expandItem: 0,
        }
    },
    watch: {
        'settings.analytics': function(val){
            if(!val){
                this.settings.adds = false;
            }
        }
    },
    mounted() {
        this.initCookies();
    },
    methods: {
        initCookies() {
            let cookiesSettings = localStorage.getItem(this.name)
            if (!cookiesSettings) {
                this.show = true;
            } else {
                // cookiesSettings = JSON.parse(cookiesSettings);
                // if (cookiesSettings.analytics && this.showInformationAboutCookies.analytics) {
                //     bootstrap().then((gtag) => {}); // _ga added!
                // }
                // if (cookiesSettings.adds && this.showInformationAboutCookies.adds) {
                //     this.$gtm?.enable(true); // enable gtm
                // } else {
                //     this.$gtm?.enable(false); // disable gtm
                // }
            }
        },
        acceptAll() {
            this.settings = {
                required: true,
                analytics: true,
                //adds: true,
            };
            this.saveSettings();
        },
        saveSettings() {
            localStorage.setItem(this.name, JSON.stringify(this.settings));
            this.show = false;
            this.manageCookies = false;
            this.initCookies();
            location.reload();
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";
$cookie_accent: $yellow;
$cookie_accent_text: #000000;
$cookie_main: #1b1b1b;
$cookie_bg: rgba(255, 255, 255, 1);

.cookie-warn {
    max-height: 90%;
    width: 500px;
    max-width: calc(100% - 40px);
    background: $cookie_bg;
    color: $cookie_main;
    border-radius: 8px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    border-top: 2px solid $cookie_accent;
    overflow: auto;
    padding: 0 20px 20px;
    box-shadow: 0 6px 22px 0 rgba(0, 0, 0, .3);

    &__header {
        display: inline-block;
        padding: 6px 20px 6px 0;
        margin-bottom: 20px;
        background: $cookie_accent;
        color: $cookie_accent_text;
        font-size: 16px;
        font-weight: 700;
        position: relative;

        b {
            position: relative;
        }

        &::before {
            content: '';
            width: 600px;
            height: 100%;
            display: block;
            background: $cookie_accent;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__body {
        text-align: center;
        line-height: 18px;
        font-size: 12px;
    }

    .choose-item {
        border: 1px solid $cookie_main;
        text-align: left;

        &:not(:last-child) {
            border-bottom: none;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-weight: bold;
            cursor: pointer;
        }

        &__body {
            border-top: inherit;
            padding: 0;
            max-height: 0;
            overflow: hidden;
            visibility: hidden;
            transition: .6s;

            &.active {
                max-height: 1000px;
                visibility: visible;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .button {
        font-size: 11px;
        text-transform: uppercase;
        font-family: Arial, sans-serif;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        padding: 5px 14px;
        opacity: 1;
        transition: opacity 0.2s ease-out;

        &:hover {
            opacity: .8;
            padding: 5px 14px;
        }

        &-main {
            color: $cookie_accent_text;
            background: $cookie_accent;
        }

        &-sec {
            //color: $cookie_accent;
            text-decoration: underline;
            background: none;
        }
    }

    a {
        color: $cookie_main;
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 600px) {
    .cookie-warn {
        width: auto;
        max-width: 100%;
        border-radius: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.more{
    font-size: 10px;
    text-decoration: underline;
    color: lighten($cookie_main, 50%) !important;
}
</style>
